<template>
  <div class="wrap">
    <div class="search-container">
      <el-row>
          <el-form ref="form" :model="form" label-width="80px">
              <el-col :span="6">
                <el-form-item label="参数名称">
                  <el-input clearable v-model="form.configName"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="参数键名">
                  <el-input clearable v-model="form.configKey"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="系统内置">
                  <el-select clearable placeholder="请选择" v-model="form.configType">
                    <el-option value="Y" label="是"></el-option>
                    <el-option value="N" label="否"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
          </el-form>
        <el-col :span="6" style="text-align:right">
          <el-button @click="searchEvent" class="search-button">{{$t('search')}}</el-button>
          <el-button @click="cleanForm" class="clean-button">{{$t('clean')}}</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="table-container">
      <el-table
				:data="tableData"
        :height="tableHeight"
				style="width: 100%"
        border
        @sort-change='sort_change'
        :row-class-name="tableRowClassName"
				@selection-change="handleSelectionChange">
         <el-table-column
            type="index"
            align="center"
            label="序号"
            width="55">
          </el-table-column>
				<el-table-column
					type="selection"
          align="center"
					width="55">
				</el-table-column>
				<el-table-column
					prop="configId"
          align="center"
					label="参数主键"
					width="180">
				</el-table-column>
				<el-table-column
					prop="configName"
          align="center"
					label="参数名称"
					width="180">
				</el-table-column>
				<el-table-column
					prop="configKey"
          align="center"
					label="参数键名"
					width="180">
				</el-table-column>
				<el-table-column
					prop="configValue"
          align="center"
					label="参数键值"
					width="180">
				</el-table-column>
				<el-table-column
					prop="configType"
          align="center"
					label="系统内置"
					width="180">
          <template slot-scope="scope">
            {{ scope.row.configType | typeFilter }}
          </template>
				</el-table-column>
				<el-table-column
					prop="remark"
          align="center"
					label="备注"
					width="180">
				</el-table-column>
        <el-table-column
          align="center"
          min-width="200"
					prop="createTime"
          sortable='custom'
					label="创建时间">
				</el-table-column>
        <el-table-column
          align="center"
          width="400px"
					:label="$t('option')">
          <template slot-scope="scope">
            <el-button class="default-button" v-if="buttonAuth.includes('system:config:edit')" @click="changeAddDialog(true, true, scope.row)">{{$t('edit')}}</el-button>
            <el-button class="default-button" v-if="buttonAuth.includes('system:config:remove')" @click="openDeleteDialog([scope.row.configId])">{{$t('delete')}}</el-button>
          </template>
				</el-table-column>
			</el-table>
    </div>
    <div class="page-wrapper">
      <div class="">
        <el-button class="default-button" v-if="buttonAuth.includes('system:config:add')" @click="changeAddDialog(true)">{{$t('add')}}</el-button>
        <el-button class="default-button" v-if="buttonAuth.includes('system:config:remove')" @click="openDeleteDialog(selectedArr.map(item => item.configId))">{{$t('delete')}}</el-button>
      </div>
      <el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="form.pageNum"
				:page-sizes="[10, 20, 30, 40]"
				:page-size="form.pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="form.total">
			</el-pagination>
    </div>
    <!-- 添加/修改弹窗 -->
    <add-dialog ref="addDialog" :Language="Language" :show="addDialog.visible" :isEdit="addDialog.isEdit" @closeDialog="changeAddDialog"></add-dialog>
    <!-- 删除确认弹窗 -->
    <confirm-dialog ref="confirmDialog" :Language="Language" :show="confirmDialog.visible" :type="confirmDialog.type" @sureDialog="sureConfirmDialog" @closeDialog="changeConfirmDialog"></confirm-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import UserSearchClass from './userSearchClass'
import apis from '@/apis'
import mixin from '@/mixins/index'
import addDialog from './dialog/addDialog.vue'
import confirmDialog from '@/components/confirmDialog.vue'

export default {
  components: {
    addDialog,
    confirmDialog,
  },
  mixins: [mixin],
  data() {
    return {
      form: new UserSearchClass('form'), // 实例化一个表单的变量
      tableData: [{}],
      addDialog: {
        visible: false,
        isEdit: false,
      },
      confirmDialog: {
        visible: false,
        type: 1,
        data: {},
      },
    }
  },
  computed: {
    ...mapState(['Language']),
  },
  filters: {
    typeFilter(type) {
      const typeMap = {
        'Y': '是',
        'N': '否',
      }
      return typeMap[type]
    },
  },
  methods: {
    /**
     * @function 搜索方法
     */
    searchData() {
      this.$http.get(apis.config_List, {
        params: this.form,
      }).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.rows
          this.form.total = res.data.total
        }
      })
    },
    /**
     * @function 清空搜索栏
     */
    cleanForm() {
      this.form = new UserSearchClass('form')
      this.$nextTick(() => {
        this.searchEvent()
      })
    },
    /**
     * @function 改变新增/修改弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Boolean} isEdit 是否是修改弹窗，如果是则要调用接口，进行回显
     * @param {Object} data 修改回显时，传给后端的值
     */
    changeAddDialog(dialogStatus, isEdit = false, data) {
      if (dialogStatus) {
        this.addDialog.isEdit = isEdit
        if (isEdit) {
          // 调用vuex里面的获取数据的业务，如果要在这里写接口，需要改成Promise
          this.$refs['addDialog'].getDataQuery(data)
        }
        // 这么写才不会影响最后的初始化，如果赋值完直接打开，会影响最后的初始化
      } else {
        this.searchEvent()
      }
      this.$nextTick(() => {
        this.addDialog.visible = dialogStatus
      })
    },
    /**
     * @function 当确认弹窗点击确定的时候触发
     */
    sureConfirmDialog() {
      // 如果一个页面有多个弹窗，视情况决定用if语句还是二元表达式对api进行判断
      this.$http.post(apis.config_remove, {}, { params: this.confirmDialog.data }).then((res) => {
        if (res.data.code === 0) {
          this.$refs.confirmDialog.closeDialog()
        }
      })
    },
    /**
     * @function 打开删除弹窗
     * @param {Array} id 选中的ID数组
     */
    openDeleteDialog(id) {
      if (id.length === 0) {
        this.$message.error('请先选择数据')
        return
      }
      this.confirmDialog.data.ids = id.join(',')
      this.changeConfirmDialog(true, 1)
    },
    /**
     * @function 打开确认重置密码弹窗
     * @param {Array} data 要重置密码的值
     */
    openConfirmDialog(data) {
      this.confirmDialog.data = data
      this.changeConfirmDialog(true, 2)
    },
    /**
     * @function 改变确认/删除弹窗的状态
     * @param {Boolean} dialogStatus 是否展示当前弹窗
     * @param {Number} type 打开的弹窗类型，1为删除确认
     */
    changeConfirmDialog(dialogStatus, type = 1) {
      this.confirmDialog.type = type
      this.confirmDialog.visible = dialogStatus
      if (!dialogStatus) {
        this.confirmDialog.data.ids = ''
        this.searchEvent()
      }
    },
    /**
     * @function 修改用户启用状态
     * @param {Object} data 用户信息
     */
    changeStatus(data) {
      const form = {
        ...data,
        ...{
          status: data.status === '1' ? '0' : '1',
        },
      }
      this.$http.post(apis.userStatus, form)
    },
  },
}
</script>
<style lang="less" scoped>
@import "../../../assets/styles/mixin.scoped.less";
.page-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 20px;
  margin-top: 5px;
}
</style>
<style lang="less">
@import "../../../assets/styles/mixin.less";
</style>
