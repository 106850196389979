
class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.configName = ''
      this.configKey = ''
      this.configValue = ''
      this.configType = 'Y'
      this.remark = ''
      this.createTime = ''
      this.updateTime = ''
    } else if (type === 'rule') {
      this.configName = [
        {
          required: true,
          message: '请输入参数名称',
          trigger: 'blur',
        },
      ]
      this.configKey = [
        {
          required: true,
          message: '请输入参数键名',
          trigger: 'blur',
        },
      ]
      this.configValue = [
        {
          required: true,
          message: '请输入参数键值',
          trigger: 'change',
        },
      ]
      this.remark = [
        {
          required: true,
          message: '请输入备注',
          trigger: 'change',
        },
      ]
    }
  }
}
export default searchFrom
