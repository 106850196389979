import pageClass from '@/unit/pageClass'

class searchFrom extends pageClass {
  constructor(type) {
    super()
    if (type === 'form') {
      this.configName = ''
      this.configKey = ''
      this.configType = ''
      this.sortField = 'createTime'
      this.sortOrder = 'desc'
    }
  }
}
export default searchFrom
